import React from "react"
import { Link } from "gatsby"
import { ChevronRightIcon } from "@heroicons/react/solid"

import NewsCard from "./NewsCard"

function CatalogNews({ data }) {
  return (
    <section
      id="news"
      aria-labelledby="news-heading"
      className="mx-auto max-w-xl py-6 px-0 sm:py-24 md:px-12 lg:max-w-7xl"
    >
      <h1
        id="news-heading"
        className="text-center text-4xl font-extrabold md:text-5xl"
      >
        Новости рынка металлов
      </h1>

      <h2 className="pt-2 text-center text-xl font-light md:text-2xl">
        В новостном блоге собраны актуальные статьи об автомобильных и
        промышленных катализаторах, сажевых фильтрах, техническом серебре и
        драгоценных металлах
      </h2>

      <div className="mt-6 grid grid-cols-1 space-y-2 sm:grid-cols-3 sm:gap-x-6 sm:space-y-0">
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link
                  to="/"
                  className="text-base font-medium text-gray-500 hover:text-gray-700"
                >
                  Главная
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <Link
                  to="/news"
                  className="ml-4 text-base font-medium text-gray-500 hover:text-gray-700"
                >
                  Новости
                </Link>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div
        id="news-list"
        className="grid grid-cols-1 gap-x-3 gap-y-6 pt-4 lg:grid-cols-3"
      >
        {data.map((record, index) => (
          <NewsCard key={`news-card-${index}`} record={record} />
        ))}
      </div>
    </section>
  )
}

export default CatalogNews
