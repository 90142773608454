import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CatalogNews from "../../components/News"

import { news } from "../../components/News/data"

const NewsPage = props => {
  const { location } = props
  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title="Новости рынка металлов"
          description="В новостном блоге собраны актуальные статьи об автомобильных и
          промышленных катализаторах, сажевых фильтрах, техническом серебре и
          драгоценных металлах"
        />
      }
      <div className="overflow-hidden bg-gray-100 pt-20 lg:pt-16">
        <CatalogNews data={[...news].reverse()} />
      </div>
    </Layout>
  )
}

export default NewsPage
