import React from "react"
import { Link } from "gatsby"

function NewsCard({ record }) {
  const { link, img, title, date } = record
  return (
    <>
      <div className="relative w-full py-2 md:py-0">
        <Link
          to={link}
          className="flex min-h-full cursor-pointer flex-col items-center justify-between overflow-hidden rounded-none bg-white py-2 shadow hover:bg-gray-200 hover:shadow-md lg:rounded-xl"
        >
          <div className="mx-auto -mt-2 brightness-100 hover:brightness-90">
            <img src={img} alt={`Фото статьи о ${title}`} />
          </div>

          <div className="pt-3 text-center">
            <div className="text-base font-light text-gray-900 md:text-lg">
              {title}
            </div>
            <div className="pt-1 text-xs font-light text-gray-900 md:text-sm">
              Дата публикации: {date}
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}

export default NewsCard
